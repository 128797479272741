import React, { useState, useEffect, Fragment } from 'react';
import firstImage from '../images/primero.png';
import secondImage from '../images/segundo.png';
import thirdImage from '../images/tercero.png';
import { Link, useNavigate } from 'react-router-dom';
import TwoButtons from '../components/twoButtons';
import arrow from '../images/arrow-white.png';

const sortByMonth = (users) => {
    users.sort((a, b) => {
        if (b.month !== a.month) {
            return b.month - a.month;
        } else if (b.average !== a.average) {
            return b.average - a.average;
        } else {
            return b.wines - a.wines;
        }
    });
};

const sortByAverage = (users) => {
    users.sort((a, b) => {
        if (b.average !== a.average) {
            return b.average - a.average;
        } else if (b.wines !== a.wines) {
            return b.wines - a.wines;
        } else {
            return b.month - a.month;
        }
    });
};
const sortByWines = (users) => {
    users.sort((a, b) => {
        if (b.wines !== a.wines) {
            return b.wines - a.wines;
        } else if (b.average !== a.average) {
            return b.average - a.average;
        } else {
            return b.month - a.month;
        }
    });
};

export default function Ranking() {

    const [userData, setUserData] = useState([]);
    const [selectedButton, setSelectedButton] = useState('Mensual');
    const navigate = useNavigate();

    const fetchUserData = () => {
        const xhr = new XMLHttpRequest();
        const url = `${process.env.REACT_APP_API_URL}/users`;
        //const url = `http://wineapi/api/users`;

        xhr.open("GET", url, true);
        xhr.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    const users = JSON.parse(this.responseText);

                    // Ordenar los usuarios por puntos en month de mayor a menor inicialmente
                    sortByMonth(users);

                    setUserData(users);
                    console.log("Datos de los usuarios:", users);
                } else {
                    console.error("Error al obtener los datos de los usuarios:", this.status, this.responseText);
                }
            }
        };
        xhr.send();
    };

    useEffect(() => {
        fetchUserData();
    }, []);

    useEffect(() => {
        let sortedUsers = [...userData];
        if (selectedButton === 'Mensual') {
            sortByMonth(sortedUsers);
        } else if (selectedButton === 'General') {
            sortByAverage(sortedUsers);
        } else if (selectedButton === 'Wines') {
            sortByWines(sortedUsers);
        }
        setUserData(sortedUsers);
    }, [selectedButton]);

    const redirectToUserPage = (userId) => {
        navigate(`/user/${userId}`);
    };

    const handleButtonClick = (buttonName) => {
        setSelectedButton(buttonName);
    };
    const [hoveredDiv, setHoveredDiv] = useState(null);
    const handleMouseEnter = (divName) => {
        setHoveredDiv(divName);
    };

    const handleMouseLeave = () => {
        setHoveredDiv(null);
    };
    return (
        <div className="winegangs-container">
            <div className="ranking-container">
                <h2>Hall of Fame</h2>
                <div className="row">
                    {userData.length > 1 && (
                        <div className="col-sm-4 ranking-second">
                            <img src={secondImage} alt="Segundo" className="ranking-image" />
                            <div>{userData[1].username} {selectedButton === 'Mensual' ? userData[1].month : userData[1].average.toFixed(2) + '%'} <br />({userData[1].relevant_winegang.name ? userData[1].relevant_winegang.name : ''})</div>
                        </div>
                    )}
                    {userData.length > 0 && (
                        <div className="col-sm-4 ranking-first">
                            <img src={firstImage} alt="Primero" className="ranking-image" />
                            <div>{userData[0].username} {selectedButton === 'Mensual' ? userData[0].month : userData[0].average.toFixed(2) + '%'} <br />({userData[0].relevant_winegang.name ? userData[0].relevant_winegang.name : ''})</div>
                        </div>
                    )}
                    {userData.length > 2 && (
                        <div className="col-sm-4 ranking-third">
                            <img src={thirdImage} alt="Tercero" className="ranking-image" />
                            <div>{userData[2].username} {selectedButton === 'Mensual' ? userData[2].month : userData[2].average.toFixed(2) + '%'} <br />({userData[2].relevant_winegang.name ? userData[2].relevant_winegang.name : ''})</div>
                        </div>
                    )}
                </div>
                <div className="main-table ranking-table">
                    {hoveredDiv === 'Mensual' && <div className="hovered-info">Puntos mensuales sobre un total de 160, 40 puntos por vino</div>}
                    {hoveredDiv === 'General' && <div className="hovered-info">Porcentaje de aciertos de todos los vinos catados</div>}
                    {hoveredDiv === 'Wines' && <div className="hovered-info">Total vinos catados</div>}
                    <div className="ranking-row title-tab">
                        <div className="title">Pos.</div>
                        <div className="title ">Nombre (Gang)</div>
                        <div className="title" style={{ cursor: 'pointer' }} onClick={() => handleButtonClick('Mensual')} onMouseEnter={() => handleMouseEnter('Mensual')} onMouseLeave={handleMouseLeave} >{selectedButton === 'Mensual' ? 'Mes' : 'Mes ▼'}</div>
                        <div className="title" style={{ cursor: 'pointer' }} onClick={() => handleButtonClick('General')} onMouseEnter={() => handleMouseEnter('General')} onMouseLeave={handleMouseLeave} >{selectedButton === 'General' ? 'Nivel' : 'Nivel ▼'}</div>
                        <div className="title" style={{ cursor: 'pointer' }} onClick={() => handleButtonClick('Wines')} onMouseEnter={() => handleMouseEnter('Wines')} onMouseLeave={handleMouseLeave} >{selectedButton === 'Wines' ? 'Vinos' : 'Vinos ▼'}</div>
                    </div>
                    <div className="ranking-row">
                        {userData.map((user, index) => (
                            <Fragment key={index}>
                                <div className="pos">{index + 1}</div>
                                <button className="item" onClick={() => redirectToUserPage(user.id)}>{user.username} ({user.relevant_winegang.name ? user.relevant_winegang.name : ''})</button>
                                <div className="item">{user.month}</div>
                                <div className="item">{user.average.toFixed(2)}%</div>
                                <div className="item">{user.wines}</div>
                            </Fragment>
                        ))}
                    </div>
                </div>
                {/* }
                <div className="second-buttons">
                    <button className={selectedButton === 'Mensual' ? 'selected-button' : ''} onClick={() => handleButtonClick('Mensual')}>Mensual</button>
                    <button className={selectedButton === 'General' ? 'selected-button' : ''} onClick={() => handleButtonClick('General')}>General</button>
                </div>
                */}
                <div className="ranking-buttons row">
                    <button className="rank-indi"><Link to="/ranking">Individual</Link></button>
                    <button className="rank-wine"><Link to="/ranking-gangs">Winegangs</Link></button>
                </div>
            </div>
            <TwoButtons />
        </div>
    );
}
