import React, { useState, useEffect, Fragment } from 'react';
import firstImage from '../images/primero.png';
import secondImage from '../images/segundo.png';
import thirdImage from '../images/tercero.png';
import { Link, useNavigate } from 'react-router-dom';
import TwoButtons from '../components/twoButtons';
import arrow from '../images/arrow-white.png';

const sortByMonth = (winegangs) => {
    winegangs.sort((a, b) => {
        if (b.month !== a.month) {
            return b.month - a.month;
        } else if (b.average !== a.average) {
            return b.average - a.average;
        } else {
            return b.wines - a.wines;
        }
    });
};

const sortByAverage = (winegangs) => {
    winegangs.sort((a, b) => {
        if (b.average !== a.average) {
            return b.average - a.average;
        } else if (b.wines !== a.wines) {
            return b.wines - a.wines;
        } else {
            return b.month - a.month;
        }
    });
};

const sortByWines = (winegangs) => {
    winegangs.sort((a, b) => {
        if (b.wines !== a.wines) {
            return b.wines - a.wines;
        } else if (b.average !== a.average) {
            return b.average - a.average;
        } else {
            return b.month - a.month;
        }
    });
};
export default function Ranking() {
    const [winegangData, setWinegangData] = useState([]);
    const [selectedButton, setSelectedButton] = useState('Mensual');
    const navigate = useNavigate();

    const fetchWinegangData = () => {
        const xhr = new XMLHttpRequest();
        const url = `${process.env.REACT_APP_API_URL}/winegangs`;
        //const url = `http://wineapi/api/winegangs`;

        xhr.open("GET", url, true);
        xhr.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    const winegangs = JSON.parse(this.responseText);

                    // Ordenar las winegangs por puntos en month de mayor a menor inicialmente
                    sortByMonth(winegangs);

                    setWinegangData(winegangs);
                    console.log("Datos de las winegangs:", winegangs);
                } else {
                    console.error("Error al obtener los datos de las winegangs:", this.status, this.responseText);
                }
            }
        };
        xhr.send();
    };

    useEffect(() => {
        fetchWinegangData();
    }, []);

    useEffect(() => {
        let sortedWinegangs = [...winegangData];
        if (selectedButton === 'Mensual') {
            sortByMonth(sortedWinegangs);
        } else if (selectedButton === 'General') {
            sortByAverage(sortedWinegangs);
        } else if (selectedButton === 'Wines') {
            sortByWines(sortedWinegangs);
        }
        setWinegangData(sortedWinegangs);
    }, [selectedButton]);

    const redirectToUserPage = (winegangId) => {
        navigate(`/gangs/${winegangId}`);
    };

    const handleButtonClick = (buttonName) => {
        setSelectedButton(buttonName);
    };
    const [hoveredDiv, setHoveredDiv] = useState(null);

    const handleMouseEnter = (divName) => {
        setHoveredDiv(divName);
    };

    const handleMouseLeave = () => {
        setHoveredDiv(null);
    };
    return (
        <div className="winegangs-container">
            <div className="ranking-container">
                <h2>Hall of Fame</h2>
                <div className="row">
                    <div className="col-sm-4 ranking-second">
                        <img src={secondImage} alt="Segundo" className="ranking-image" />
                        {winegangData.length > 1 && (
                            <div>{winegangData[1].winegang_name} {selectedButton === 'Mensual' ? winegangData[1].month : winegangData[1].average.toFixed(2) + '%'}</div>
                        )}
                    </div>
                    <div className="col-sm-4 ranking-first">
                        <img src={firstImage} alt="Primero" className="ranking-image" />
                        {winegangData.length > 0 && (
                            <div>{winegangData[0].winegang_name} {selectedButton === 'Mensual' ? winegangData[0].month : winegangData[0].average.toFixed(2) + '%'}</div>
                        )}
                    </div>
                    <div className="col-sm-4 ranking-third">
                        <img src={thirdImage} alt="Tercero" className="ranking-image" />
                        {winegangData.length > 2 && (
                            <div>{winegangData[2].winegang_name} {selectedButton === 'Mensual' ? winegangData[2].month : winegangData[2].average.toFixed(2) + '%'}</div>
                        )}
                    </div>
                </div>
                
                <div className="main-table main-table-ranking-gangs">
                    {hoveredDiv === 'Mensual' && <div className="hovered-info">Suma de los puntos de los jugadores de la winegang</div>}
                    {hoveredDiv === 'General' && <div className="hovered-info">Porcentaje de aciertos de la winegang</div>}
                    {hoveredDiv === 'Wines' && <div className="hovered-info">Total vinos catados</div>}
                    <div className="ranking-row title-tab">
                        <div className="title">Pos.</div>
                        <div className="title">Winegang (miembros)</div>
                        <div
                            className="title"
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleButtonClick('Mensual')}
                            onMouseEnter={() => handleMouseEnter('Mensual')}
                            onMouseLeave={handleMouseLeave}
                        >   
                            {selectedButton === 'Mensual' ? 'Mes' : 'Mes ▼'}
                        </div>
                        <div
                            className="title"
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleButtonClick('General')}
                            onMouseEnter={() => handleMouseEnter('General')}
                            onMouseLeave={handleMouseLeave}
                        >
                            {selectedButton === 'General' ? 'Nivel' : 'Nivel ▼'}
                        </div>
                        <div
                            className="title"
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleButtonClick('Wines')}
                            onMouseEnter={() => handleMouseEnter('Wines')}
                            onMouseLeave={handleMouseLeave}
                        >
                            {selectedButton === 'Wines' ? 'Vinos' : 'Vinos ▼'}
                        </div>
                        
                    </div>
                    <div className="ranking-row">
                        {winegangData.map((winegang, index) => (
                            <Fragment key={index}>
                                <div className="pos">{index + 1}</div>
                                <button className="item" onClick={() => redirectToUserPage(winegang.id)}>{winegang.winegang_name} ({winegang.members})</button>
                                <div className="item">{winegang.month}</div>
                                <div className="item">{winegang.average.toFixed(2)}%</div>
                                <div className="item">{winegang.wines}</div>
                            </Fragment>
                        ))}
                    </div>
                </div>
                <div className="ranking-buttons row rank-gangs">
                    <button className="col rank-indi"><Link to="/ranking">Individual</Link></button>
                    <button className="col rank-wine"><Link to="/ranking-gangs">Winegangs</Link></button>
                </div>
            </div>
            <TwoButtons />
        </div>
    );
}
