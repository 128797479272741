import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Login() {
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });

    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setErrors({}); // Limpiar errores anteriores

        // Validaciones básicas
        if (!/\S+@\S+\.\S+/.test(formData.email)) {
            setErrors(errors => ({ ...errors, email: "Formato de correo electrónico inválido" }));
            return;
        }

        try {
            let xhr = new XMLHttpRequest();
            var data = new FormData();
            data.append('email', formData.email);
            data.append('password', formData.password);

            //let url = 'https://admin.winegang.es/api/login';
            let url = `${process.env.REACT_APP_API_URL}/login`;
            xhr.open("POST", url, true);
            xhr.onreadystatechange = function () {
                if (this.readyState === 4) {
                    if (this.status === 200) {
                        const user = JSON.parse(this.responseText);
                        localStorage.setItem('userLogged', JSON.stringify({ username: user.username, profile: user.profile, wines: user.wines, average: user.average, id:user.id }));
                        console.log(user);
                        navigate('/');  // Redirecciona al home o a la página que prefieras tras el login
                    } else {
                        setErrors(errors => ({ ...errors, general: "Error de login, verifica tus credenciales" }));
                    }
                }
            };
            xhr.send(data);
        } catch (error) {
            console.error('Error:', error);
            setErrors(errors => ({ ...errors, general: "Error de conexión" }));
        }
    };
    const handleRegisterClick = () => {
        navigate('/register');  // Función para navegar a /register
    };
    const handlePasswordClick = () => {
        navigate('/reset-pass');  // Función para navegar a /register
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    return (
        <div className="winegangs-container">
            <div className="register-container">
                <h2>Login</h2>
                <form onSubmit={handleLogin}>
                    <div className="form-line">
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" value={formData.email} onChange={handleChange} placeholder="juan@example.com" />
                    </div>
                    {errors.email && <p className="error">{errors.email}</p>}
                    <div className="form-line">
                        <label htmlFor="password">Contraseña</label>
                        <input type="password" id="password" value={formData.password} onChange={handleChange} placeholder="********" />
                    </div>
                    {errors.password && <p className="error">{errors.password}</p>}
                    {errors.general && <p className="error">{errors.general}</p>}
                    <p className="link" onClick={handleRegisterClick} style={{ cursor: "pointer", color: "blue" }}>Crear cuenta</p>
                    <p className="link" onClick={handlePasswordClick} style={{ cursor: "pointer", color: "blue" }}>Recuperar contraseña</p>
                    <button type="submit">Enviar</button>
                </form>
            </div>
        </div>
    );
}
