import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Login() {
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });

    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setErrors({}); // Limpiar errores anteriores

        // Validaciones básicas
        if (!/\S+@\S+\.\S+/.test(formData.email)) {
            setErrors(errors => ({ ...errors, email: "Formato de correo electrónico inválido" }));
            return;
        }

        try {
            let xhr = new XMLHttpRequest();
            var data = new FormData();
            data.append('email', formData.email);


            let url = `${process.env.REACT_APP_API_URL}/resetpass`;
            xhr.open("POST", url, true);
            xhr.onreadystatechange = function () {
                if (this.readyState === 4) {
                    if (this.status === 200) {
                        navigate('/');  // Redirecciona al home o a la página que prefieras tras el reset
                    } else {
                        let response;
                        try {
                            response = JSON.parse(this.responseText);
                        } catch (error) {
                            response = { message: "Error desconocido, intenta nuevamente" };
                        }

                        // Manejo de errores específicos del servidor
                        if (this.status === 404) {
                            setErrors(errors => ({ ...errors, general: "Usuario no encontrado" }));
                        } else if (this.status === 500) {
                            setErrors(errors => ({ ...errors, general: "Error del servidor, intenta nuevamente" }));
                        } else {
                            setErrors(errors => ({ ...errors, general: response.message || "Error desconocido, intenta nuevamente" }));
                        }
                    }
                }
            };
            xhr.send(data);
        } catch (error) {
            console.error('Error:', error);
            setErrors(errors => ({ ...errors, general: "Error de conexión" }));
        }
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };
    const handlePasswordClick = () => {
        navigate('/');  // Función para navegar a /register
    };
    return (
        <div className="winegangs-container">
            <div className="register-container">
                <h2>Recuperar contraseña </h2>
                <p className="link" >Te mandaremos una nueva contraseña al email, podrás cambiarla una vez que accedas de nuevo a tu cuenta. Si no encuentras el correo revisa la carpeta de spam</p>
                <form onSubmit={handleLogin}>
                    <div className="form-line">
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" value={formData.email} onChange={handleChange} placeholder="juan@example.com" />
                    </div>
                    {errors.email && <p className="error">{errors.email}</p>}
                    {errors.general && <p className="error">{errors.general}</p>}
                    <p className="link" onClick={handlePasswordClick} style={{ cursor: "pointer", color: "blue" }}>Volver</p>
                    <button type="submit">Enviar</button>
                </form>
            </div>
        </div>
    );
}
