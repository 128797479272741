import React, { useState, useEffect, Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Profile from '../components/profile';
import TwoButtons from '../components/twoButtons';
import notify from '../images/notify.png';

export default function Gang() {
    const [profileInfo, setProfileInfo] = useState({
        id: 0
    });
    const navigate = useNavigate();
    const [userData, setUserData] = useState(0);
    const [notifications, setNotifications] = useState({ invitations: [], winegangs: [] });

    useEffect(() => {
        // Cargar la informaci�n del usuario desde localStorage
        const storedUserInfo = localStorage.getItem('userLogged');
        if (storedUserInfo) {
            const userInfo = JSON.parse(storedUserInfo);
            console.log(userInfo);
            setProfileInfo({
                id: userInfo.id || 0,
            });
        }
    }, []);

    const fetchWinegangsData = () => {
        console.log(profileInfo.id);
        const xhr = new XMLHttpRequest();
        const url = `${process.env.REACT_APP_API_URL}/user/${profileInfo.id}`;
        //const url = `http://wineapi/api/user/${id}`;

        xhr.open("GET", url, true);
        xhr.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    const userData = JSON.parse(this.responseText);
                    setUserData(userData);
                    console.log("Datos del usuario:", userData);
                } else {
                    console.error("Error al obtener los datos del usuario:", this.status, this.responseText);
                }
            }
        };
        xhr.send();
    };
    const fetchNotifData = () => {
        if (profileInfo && profileInfo.id) {
            const xhr = new XMLHttpRequest();
            const url = `${process.env.REACT_APP_API_URL}/hasNotifications/${profileInfo.id}`;
            xhr.open("GET", url, true);
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4 && xhr.status === 200) {
                    const data = JSON.parse(xhr.responseText);
                    setNotifications({
                        invitations: data.invitations || [],
                        winegangs: data.winegangs || []
                    });
                    console.log(data);
                }
            };
            xhr.send();
        }
    };

    useEffect(() => {
        if (profileInfo && profileInfo.id) { // A�adido check para asegurar que profileInfo tiene un id
            fetchWinegangsData();
            fetchNotifData();
        }
    }, [profileInfo]);

    const redirectToUserPage = (userId) => {
        navigate(`/gangs/${userId}`);
    };

    const shouldShowNotification = () => {
        return notifications.invitations.length > 0;
    };
    const winegangNotification = (winegangId) => {
        const winegangIdAsNumber = Number(winegangId);
        console.log(`Checking notification for winegang ID: ${winegangIdAsNumber}`);

        if (notifications && notifications.winegangs) {
            const result = notifications.winegangs.some(winegang => Number(winegang.winegang_id) === winegangIdAsNumber);
            console.log(`Result for winegang ID ${winegangIdAsNumber}: ${result}`);
            return result;
        }
        return false;
    };

    return (
        <div className="winegangs-container">
            <Profile />
            <div className="gang-button-container">
                {
                    userData && userData.winegangs && userData.winegangs.length > 0 && (
                        userData.winegangs.map((winegang, index) => (
                            <Fragment key={index}>
                                <button className="gang-button" onClick={() => redirectToUserPage(winegang.id)}><img src={notify} alt="Icono" className="notify-image" style={{ display: winegangNotification(winegang.id) ? 'block' : 'none' }} />{winegang.winegang_name}</button>
                            </Fragment>
                        ))
                    )
                }
            </div>
            <div className="gang-second-button-container">
                <button className="gang-second-button"><Link to="/gangs/crear">Crear Gang</Link></button>
                <button className="gang-second-button"><Link to="/gangs/buscar">Buscar Gang</Link></button>
                <button className="gang-second-button"><Link to="/gangs/invitaciones"><img src={notify} alt="Icono" className="notify-gang-image" style={{ display: shouldShowNotification() ? 'block' : 'none' }} />Invitaciones</Link></button>
                
            </div>
            <TwoButtons />
        </div>

    )
}
