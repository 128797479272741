import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const userLoggedIn = localStorage.getItem('userLogged');
    if (!userLoggedIn) {
        // Redirige a la p�gina de inicio de sesi�n si el usuario no est� autenticado
        return <Navigate to="/login" replace />;
    }
    return children;
};

export default ProtectedRoute; // Aseg�rate de que est� usando export default