import React, { useState, useEffect, Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import TwoButtons from '../components/twoButtons';
import WineA from '../images/wine-a.png';
import WineB from '../images/wine-b.png';
import WineC from '../images/wine-c.png';
import WineD from '../images/wine-d.png';
import close from '../images/close-stats.png';
export default function ChooseComponent() {
    const [testsData, setTestsData] = useState([]);
    const [answersData, setAnswersData] = useState([]);
    const [profileInfo, setProfileInfo] = useState({ id: 0 });
    const [isSubmitPopupVisible, setSubmitPopupVisible] = useState(true);
    const navigate = useNavigate();
   

    useEffect(() => {
        // Cargar la información del usuario desde localStorage
        const storedUserInfo = localStorage.getItem('userLogged');
        if (storedUserInfo) {
            const userInfo = JSON.parse(storedUserInfo);
            console.log(userInfo);
            setProfileInfo(userInfo);
        }
    }, []);
    const closeSubmitPopup = () => {
        navigate('/choose-answer');
    }; 
    const getTests = () => {
        if (profileInfo && profileInfo.id) {
            const xhr = new XMLHttpRequest();
            const url = `${process.env.REACT_APP_API_URL}/getAnswers/${profileInfo.id}`;
            xhr.open("GET", url, true);
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        const data = JSON.parse(xhr.responseText);
                        setTestsData(data.tests);
                        setAnswersData(data.answers);
                        console.log(data);


                    } else if (xhr.status === 204) {
                    }
                }
            };
            xhr.send();
        }
    };

    useEffect(() => {
        if (profileInfo && profileInfo.id) { // Añadido check para asegurar que profileInfo tiene un id
            getTests();
        }
    }, [profileInfo]);

    const handleWineClick = (e, wineTitle) => {
        const selectedTest = testsData.find(test => test.title === wineTitle);

        if (selectedTest) {
            const selectedAnswer = answersData.find(answer => Number(answer.test_id) === Number(selectedTest.id));

            if (selectedAnswer && selectedAnswer.object) {
                localStorage.setItem('currentTest', JSON.stringify(selectedTest));
                localStorage.setItem('currentAnswer', JSON.stringify(selectedAnswer.object));
            } else {
                e.preventDefault(); // Previene la navegación si la respuesta no está disponible o es inválida
            }
        } else {
            e.preventDefault(); // Previene la navegación si el test no está disponible
        }
    };






    const isWineDisabled = (wineTitle) => {
        return !testsData.some(test => test.title === wineTitle);
    };

    return (
        <div className="test-container">
            <div className="slider-container">
                <div className="slide-container">
                    <h2 className="">Tus últimas respuestas</h2>
                    <div className="choose-wine-container">
                        <Link 
                            to="/answer" 
                            className={`image-link ${isWineDisabled('A') ? 'disabled' : ''}`}
                            onClick={(e) => handleWineClick(e, 'A')}
                        >
                            <img src={WineA} alt="Imagen 1" className="grid-image" />
                            <span>A</span>
                        </Link>
                        <Link 
                            to="/answer" 
                            className={`image-link ${isWineDisabled('B') ? 'disabled' : ''}`}
                            onClick={(e) => handleWineClick(e, 'B')}
                        >
                            <img src={WineB} alt="Imagen 1" className="grid-image" />
                            <span>B</span>
                        </Link>
                        <Link 
                            to="/answer" 
                            className={`image-link ${isWineDisabled('C') ? 'disabled' : ''}`}
                            onClick={(e) => handleWineClick(e, 'C')}
                        >
                            <img src={WineC} alt="Imagen 1" className="grid-image" />
                            <span>C</span>
                        </Link>
                        <Link 
                            to="/answer" 
                            className={`image-link ${isWineDisabled('D') ? 'disabled' : ''}`}
                            onClick={(e) => handleWineClick(e, 'D')}
                        >
                            <img src={WineD} alt="Imagen 1" className="grid-image" />
                            <span>D</span>
                        </Link>
                    </div>
                </div>
            </div>
            <TwoButtons />
            {isSubmitPopupVisible && (
                <Popup
                    text="No hay ningún juego activo, pero puedes ver tus últimas respuestas"
                    onClose={closeSubmitPopup}
                    isHelpPopup={false}
                />
            )}
        </div>
    );
};
const Popup = ({ text, onClose, onConfirm, imageUrl, isHelpPopup }) => {
    return (
        <div className="custom-overlay">
            <div className={`custom-popup ${isHelpPopup ? 'popup-noback' : ''}`}>
                <button className="stats-close" onClick={onClose}><img src={close} alt="close" /></button>
                {text && <p>{text}</p>}
                {imageUrl && (
                    <div className="image-container">
                        <img src={imageUrl} alt="Ayuda" />
                    </div>
                )}
                {onConfirm && <button onClick={onConfirm}>Enviar</button>}
            </div>
        </div>
    );
};