import React, { useState, useEffect } from 'react';
import icon from '../images/icon-o.png';
import instructions from '../images/instruct-o.png';
import Rinstructions from '../images/r-instruct-o.png';

const SlideComponent = ({ actual, first, second, third, fourth }) => {
    const [selectedButtonGroup1, setSelectedButtonGroup1] = useState(null);
    const [selectedButtonsGroup2, setSelectedButtonsGroup2] = useState({});
    const [title, setTitle] = useState(null);

    useEffect(() => {
        const savedAnswer = JSON.parse(localStorage.getItem('currentAnswer') || '[]');
        console.log(savedAnswer);
        const savedTest = JSON.parse(localStorage.getItem('currentTest') || '[]');
        setTitle(savedTest.title);
        const savedButtonGroup1 = savedAnswer.selectedButtonGroup2;
        const savedButtonsGroup2 = {
            1: savedAnswer.selectedButtonGroup3 ,
            2: savedAnswer.selectedButtonGroup4,
            3: savedAnswer.selectedButtonGroup5,
        };

        if (savedButtonGroup1) setSelectedButtonGroup1(savedButtonGroup1);
        setSelectedButtonsGroup2(savedButtonsGroup2);
    }, []);

    useEffect(() => {
        const nextButton = document.querySelector('.btn-next');
        const selectedCount = Object.values(selectedButtonsGroup2).reduce((total, current, index) => {
            if (index + 1 === 2) { // Asumiendo que 2 es el grupo de "Aromas Secundarios"
                return total + (current.includes(third.question[0].option) ? 2 : current.length);
            }
            return total + current.length;
        }, 0);

        nextButton.disabled = !(selectedCount === 6 && selectedButtonGroup1 !== null);
    }, [selectedButtonGroup1, selectedButtonsGroup2, third]);

    const isButtonSelectedGroup2 = (column, buttonId) => {
        return selectedButtonsGroup2[column]?.includes(buttonId);
    };

    const handleQuitInstructions = () => {
        const instructionsDiv = document.getElementById('instructions-div');
        if (instructionsDiv) {
            instructionsDiv.style.display = 'none';
        }
    };

    return (
        <div className="slide-container">
            <div className="overlay" id="instructions-div">
                <img src={instructions} alt="Imagen" className="centered-image dekstop" onClick={handleQuitInstructions} />
                <img src={Rinstructions} alt="Imagen" className="centered-image mobile" onClick={handleQuitInstructions} />
            </div>
            <img className="icon-test" src={icon} alt="icon" />
            <div className="actual-wine">{title}</div>
            <h2 className="mb-3">Fase Olfativa</h2>
            <div className="section-container" role="group">
                <div className="section-title">Intensidad del Aroma</div>
                {first.question.map((item, index) => (
                    <button
                        key={index}
                        type="button"
                        className={`btn ${selectedButtonGroup1 === item.option ? 'btn-selected' : 'btn-olfatory'}`}
                    >
                        {item.option}
                    </button>
                ))}
            </div>

            <div className="cont-sub-section">
                <div key='1' className="col-sub-section sub-section-container">
                    <div className="section-title">Aromas Primarios</div>
                    {second.question.map((item, index) => (
                        <button
                            key={index}
                            type="button"
                            className={`btn ${isButtonSelectedGroup2(1, item.option) ? 'btn-selected' : 'btn-olfatory'}`}
                        >
                            {item.option}
                        </button>
                    ))}
                </div>
                <div key='2' className="col-sub-section sub-section-container">
                    <div className="section-title">Aromas Secundarios</div>
                    {third.question.map((item, index) => (
                        <button
                            key={index}
                            type="button"
                            className={`btn ${isButtonSelectedGroup2(2, item.option) ? 'btn-selected' : 'btn-olfatory'}`}
                            id={item.option}
                        >
                            {item.option}
                        </button>
                    ))}
                </div>
                <div key='3' className="col-sub-section sub-section-container">
                    <div className="section-title">Aromas Terciarios</div>
                    {fourth.question.map((item, index) => (
                        <button
                            key={index}
                            type="button"
                            className={`btn ${isButtonSelectedGroup2(3, item.option) ? 'btn-selected' : 'btn-olfatory'}`}
                        >
                            {item.option}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SlideComponent;
