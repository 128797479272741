import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/layout";
import Home from "./pages/Home";
import Test from "./pages/test";
import Answer from "./pages/Answer";
import ChooseWine from "./pages/ChooseWine";
import ChooseAnswer from "./pages/ChooseAnswer";
import ChooseAnswerAlert from "./pages/ChooseAnswerAlert";
import NoPage from "./pages/NoPage";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Ranking from "./pages/Ranking";
import User from "./pages/User";
import EditUser from "./pages/EditUser";
import EditData from "./pages/EditData";
import Stats from "./pages/Stats";
import RankingGangs from "./pages/RankingGangs";
import Gangs from "./pages/Gangs";
import CrearGang from "./gangPages/crear";
import LiderGang from "./gangPages/lider";
import InvitarGang from "./gangPages/invitar";
import SolicitudesGang from "./gangPages/solicitudes";
import InvitacionesGang from "./gangPages/invitaciones";
import BuscarGang from "./gangPages/buscar";
import UsuarioGang from "./gangPages/usuario";
import EditGang from "./gangPages/editar";
import ResetPass from "./pages/ResetPass";
import OrderWine from "./pages/OrderWine.js";
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import ProtectedRoute from "./functions/ProtectedRoute";


export default function App() {
    
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
                  <Route index element={<ProtectedRoute><Home /></ProtectedRoute>} />
                  <Route path="test" element={<ProtectedRoute><Test /></ProtectedRoute>} />
                  <Route path="answer" element={<ProtectedRoute><Answer /></ProtectedRoute>} />
                  <Route path="wine-order" element={<ProtectedRoute><OrderWine /></ProtectedRoute>} />
                  <Route path="choose-wine" element={<ProtectedRoute><ChooseWine /></ProtectedRoute>} />
                  <Route path="choose-answer" element={<ProtectedRoute><ChooseAnswer /></ProtectedRoute>} />
                  <Route path="choose-answer-alert" element={<ProtectedRoute><ChooseAnswerAlert /></ProtectedRoute>} />
                  <Route path="ranking" element={<ProtectedRoute><Ranking /></ProtectedRoute>} />
                  <Route path="ranking-gangs" element={<ProtectedRoute><RankingGangs /></ProtectedRoute>} />
                  <Route path="user/:id" element={<ProtectedRoute><User /></ProtectedRoute>} />
                  <Route path="edit-user" element={<ProtectedRoute><EditUser /></ProtectedRoute>} />
                  <Route path="edit-data" element={<ProtectedRoute><EditData /></ProtectedRoute>} />
                  <Route path="stats/:id" element={<ProtectedRoute><Stats /></ProtectedRoute>} />
                  <Route path="gangs" element={<ProtectedRoute><Gangs /></ProtectedRoute>} />
                  <Route path="gangs/crear" element={<ProtectedRoute><CrearGang /></ProtectedRoute>} />
                  <Route path="gangs/editar/:id" element={<ProtectedRoute><EditGang /></ProtectedRoute>} />
                  <Route path="gangs/lider" element={<ProtectedRoute><LiderGang /></ProtectedRoute>} />
                  <Route path="gangs/invitar/:id" element={<ProtectedRoute><InvitarGang /></ProtectedRoute>} />
                  <Route path="gangs/solicitudes/:id" element={<ProtectedRoute><SolicitudesGang /></ProtectedRoute>} />
                  <Route path="gangs/invitaciones" element={<ProtectedRoute><InvitacionesGang /></ProtectedRoute>} />
                  <Route path="gangs/buscar" element={<ProtectedRoute><BuscarGang /></ProtectedRoute>} />
                  <Route path="gangs/:id" element={<ProtectedRoute><UsuarioGang /></ProtectedRoute>} />
                  <Route path="login" element={<Login />} />
                  <Route path="reset-pass" element={<ResetPass />} />
                  <Route path="register" element={<Register />} />
                  <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);