import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import Pagina1 from '../answerComponents/visualComponent';
import Pagina2 from '../answerComponents/olfactoryComponent';
import Pagina3 from '../answerComponents/tasteComponent';
import Pagina4 from '../answerComponents/noteComponent';
import Pagina5 from '../answerComponents/orderComponent';
import TwoButtons from '../components/twoButtons';
import close from '../images/close-stats.png';
import ayuda from '../images/ayuda/olfativa-final.jpg';

const helpUrls = [
    '',
    '',
    '',
    {
        A: 'https://admin.winegang.es/src/respuesta-A.jpg', // Cambia con el ID correcto de la imagen A.jpg
        B: 'https://admin.winegang.es/src/respuesta-B.jpg', // Cambia con el ID correcto de la imagen B.jpg
        C: 'https://admin.winegang.es/src/respuesta-C.jpg', // Cambia con el ID correcto de la imagen C.jpg
        D: 'https://admin.winegang.es/src/respuesta-D.jpg'  // Cambia con el ID correcto de la imagen D.jpg
    },
    ''
];

const SliderComponent = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [test, setTest] = useState(null);
    const [currentTest, setCurrentTest] = useState(null);
    const [isHelpPopupVisible, setHelpPopupVisible] = useState(false);
    const [isSubmitPopupVisible, setSubmitPopupVisible] = useState(false);
    const [popupImage, setPopupImage] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        // Obtener el currentTest de localStorage
        const currentTest = JSON.parse(localStorage.getItem('currentTest'));
        if (currentTest) {
            fetchTestDetails(currentTest.id);
        }
    }, []);

    const fetchTestDetails = (testId) => {
        const xhr = new XMLHttpRequest();
        const url = `${process.env.REACT_APP_API_URL}/getTest/${testId}`;
        xhr.open("GET", url, true);
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
                const data = JSON.parse(xhr.responseText);
                setTest(data.object);
                setCurrentTest(data.title);
                console.log("Test Details Loaded: ", data);
            }
        };
        xhr.send();
    };

    const handleShowInstructions = () => {
        const instructionsDiv = document.getElementById('instructions-div');
        if (instructionsDiv) {
            instructionsDiv.style.display = 'block';
        }
    };


    useEffect(() => {
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

        if (isIOS) {
            document.body.classList.add("safari");
        }

        // Función de limpieza que se ejecuta cuando el componente se desmonta
        return () => {
            if (isIOS) {
                document.body.classList.remove("safari");
            }
        };
    }, []);

    const saveAnswersRef = useRef(null);

    const handleSaveAnswers = () => {
        if (saveAnswersRef.current) {
            saveAnswersRef.current();
        }
    };

    const renderContent = () => {
        if (!test) {
            return <div>Cargando...</div>;
        }
        // Define tu contenido para cada página
        switch (currentPage) {
            case 1:
                return <Pagina4 first={test[0]} second={test[1]} actual={currentTest.title} />;
            case 2:
                return <Pagina2 first={test[2]} second={test[3]} third={test[4]} fourth={test[5]} actual={currentTest.title} />;
            case 3:
                return <Pagina3 actual={currentTest.title}  />;
            case 4:
                return <Pagina1 actual={currentTest.title}  first={test[11]} second={test[12]} onSaveAnswers={(saveAnswers) => saveAnswersRef.current = saveAnswers} />;
            case 5:
                return <Pagina5 />;
            // Agrega más casos según sea necesario
            default:
                return null;
        }
    };


    const handleHelpClick = () => {
        let imageUrl;
        if (currentPage === 1 && currentTest) {
            const currentTestTitle = currentTest;
            const pageFourUrls = helpUrls[3];
            imageUrl = pageFourUrls[currentTestTitle] || '';
            console.log(`Current Test Title: ${currentTestTitle}, URL: ${imageUrl}`);
        } else {
            imageUrl = helpUrls[currentPage - 1];
        }

        if (imageUrl) {
            setPopupImage(imageUrl);
            setHelpPopupVisible(true);
        } else {
            console.log("No hay imagen de ayuda definida para esta página.");
        }
    };
    const showSubmitPopup = () => {
        setSubmitPopupVisible(true);
    };

    const closeHelpPopup = () => {
        setHelpPopupVisible(false);
        setPopupImage(null);
    };

    const closeSubmitPopup = () => {
        setSubmitPopupVisible(false);
    };
    const clearStorageAndRedirect = () => {

        // Redirigir a la página principal
        navigate('/choose-answer');
    };
    return (
        <div className="test-container">
            <div className="slider-container my-3">
                <button className="stats-close" onClick={clearStorageAndRedirect} ><img src={close} alt="close" /></button>
                {renderContent()}
                <div className="btn-test-container">
                </div>
                <div className="btn-second-test-container btn-answer">
                    <button className="btn"></button>
                    <button className="btn btn-help" onClick={handleHelpClick}><span>Respuestas correctas</span></button>
                </div>
            </div>
            <TwoButtons />
            {isHelpPopupVisible && popupImage && (
                <Popup
                    imageUrl={popupImage}
                    onClose={closeHelpPopup}
                    isHelpPopup={true}
                />
            )}
            {isSubmitPopupVisible && (
                <Popup
                    text="No hay ningún juego activo, pero puedes ver tus últimas respuestas"
                    onClose={closeSubmitPopup}
                    isHelpPopup={false}
                />
            )}
        </div>
    );
};

const Popup = ({ text, onClose, onConfirm, imageUrl, isHelpPopup }) => {
    return (
        <div className="custom-overlay">
            <div className={`custom-popup ${isHelpPopup ? 'popup-noback' : ''}`}>
                <button className="stats-close" onClick={onClose}><img src={close} alt="close" /></button>
                {text && <p>{text}</p>}
                {imageUrl && (
                    <div className="image-container">
                        <img src={imageUrl} alt="Ayuda" />
                    </div>
                )}
                {onConfirm && <button onClick={onConfirm}>Enviar</button>}
            </div>
        </div>
    );
};

export default SliderComponent;
