import React, { useEffect, useState } from 'react';
import instructions from '../images/instruct-w.png';
import Rinstructions from '../images/r-instruct-w.png';


const handleQuitInstructions = () => {
    const instructionsDiv = document.getElementById('instructions-div');
    if (instructionsDiv) {
        instructionsDiv.style.display = 'none';
    }
};
const SlideComponent = () => {

    const initialWines = ['A', 'B', 'C', 'D'];

    const [availableWines, setAvailableWines] = useState(initialWines);
    const [selectedWines, setSelectedWines] = useState([]);

    const handleWineClick = (wine) => {
        const newAvailableWines = availableWines.filter((w) => w !== wine);
        const newSelectedWines = [...selectedWines, wine];

        setAvailableWines(newAvailableWines);
        setSelectedWines(newSelectedWines);
    };

    const handleClearSelected = () => {
        setAvailableWines([...availableWines, ...selectedWines]);
        setSelectedWines([]);
    };


    return (
        <div className="slide-container">
            <div className="overlay" id="instructions-div">
                <img src={instructions} alt="Imagen" className="centered-image dekstop" onClick={handleQuitInstructions} />
                <img src={Rinstructions} alt="Imagen" className="centered-image mobile" onClick={handleQuitInstructions} />
            </div>
            <h2 className="mb-3">Vino del Mes</h2>
            <div className="cont-sub-section">
                <div className="col-sub-section-2 wine-container wine-container-first">
                    <div className="section-title">Vinos catados</div>
                    {availableWines.map((wine) => (
                        <button className={`wine-bottle wine-bottle-${wine}`}  key={wine} onClick={() => handleWineClick(wine)}>
                            <span>{wine}</span>                        
                        </button>
                    ))}
                </div>
                <div className="col-sub-section-2 wine-container wine-container-second">
                    <div className="section-title">Mejor vino</div>
                    <div className="wine-order">
                        <span>1&ordm;</span><span>2&ordm;</span><span>3&ordm;</span><span>4&ordm;</span></div>
                    {selectedWines.map((wine, index) => (
                        <div key={index} className={`wine-bottle selected-wine wine-bottle-${wine}`}>
                            <span>{wine}</span>
                        </div>
                    ))}
                    {selectedWines.length > 0 && (
                        <div>
                            <button className="delete-button" onClick={handleClearSelected}>X</button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};



export default SlideComponent;