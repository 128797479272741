import React, { useState, useEffect, Fragment } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Profile from '../components/profileRed';
import TwoButtons from '../components/twoButtons';
import acept from '../images/aceptar.png';

export default function Crear() {
    const [profileInfo, setProfileInfo] = useState({
        id: 0
    });
    const { id } = useParams();
    const navigate = useNavigate();
    const [winegangData, setWinegangData] = useState(0);
    const [userData, setUserata] = useState(0);


    useEffect(() => {
        // Cargar la informaci�n del usuario desde localStorage
        const storedUserInfo = localStorage.getItem('userLogged');
        if (storedUserInfo) {
            const userInfo = JSON.parse(storedUserInfo);
            console.log(userInfo);
            setProfileInfo({
                id: userInfo.id || 0,
            });
        }
    }, []);


    const fetchWinegangData = () => {
        console.log(id);
        const xhr = new XMLHttpRequest();
        const url = `${process.env.REACT_APP_API_URL}/winegang/${id}`;
        //const url = `http://wineapi/api/winegang/${id}`;

        xhr.open("GET", url, true);
        xhr.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    const winegangData = JSON.parse(this.responseText);
                    setWinegangData(winegangData);
                    console.log("Datos de la winegang:", winegangData);
                    fetchDemandsData();
                    if (Number(winegangData.admin_user_id) !== Number(profileInfo.id)) {
                        navigate('/');
                    };
                } else {
                    console.error("Error al obtener los Datos de la winegang::", this.status, this.responseText);
                }
            }
        };
        xhr.send();
    };

    const fetchDemandsData = () => {
        if (profileInfo && profileInfo.id) {  // Aseg�rate de que profileInfo est� correctamente establecido
            const xhr = new XMLHttpRequest();
            const url = `${process.env.REACT_APP_API_URL}/findDemand/${id}`;
            xhr.open("GET", url, true);
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4 && xhr.status === 200) {
                    const data = JSON.parse(xhr.responseText);
                    setUserata(data);
                    console.log(data);
                }
            };
            xhr.send();
        }
    };

    useEffect(() => {
        if (profileInfo && profileInfo.id) { // A�adido check para asegurar que profileInfo tiene un id
            fetchWinegangData();
        }
    }, [profileInfo]);

    const confirmDemand = (user_id) => {
        if (user_id && id) { // Aseg�rate de que tanto profileInfo como winegang_id est�n definidos
            const xhr = new XMLHttpRequest();
            // Incluir ambos par�metros en la URL
            const url = `${process.env.REACT_APP_API_URL}/confirmDemand/${user_id}/${id}`;
            xhr.open("GET", url, true);
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        const data = JSON.parse(xhr.responseText);
                        console.log(data);
                        fetchDemandsData();
                    } else {
                        console.error("Failed to fetch data:", xhr.status, xhr.responseText);
                    }
                }
            };
            xhr.send();
        } else {
            console.error("Invalid or missing user ID or winegang ID");
        }
    };
    const deleteDemand = (user_id) => {
        if (user_id && id) { // Aseg�rate de que tanto profileInfo como winegang_id est�n definidos
            const xhr = new XMLHttpRequest();
            // Incluir ambos par�metros en la URL
            const url = `${process.env.REACT_APP_API_URL}/deleteDemand/${user_id}/${id}`;
            xhr.open("GET", url, true);
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        const data = JSON.parse(xhr.responseText);
                        console.log(data);
                        fetchDemandsData();
                    } else {
                        console.error("Failed to fetch data:", xhr.status, xhr.responseText);
                    }
                }
            };
            xhr.send();
        } else {
            console.error("Invalid or missing user ID or winegang ID");
        }
    };

    return (
        <div className="winegangs-container">
            <Profile />
            <div className="gang-container">
                <h3>{winegangData.winegang ? winegangData.winegang.winegang_name : 'Cargando...'}</h3>

                <h2>Solicitudes</h2>
                
                <div className="sol-table">
                    <div className="tab-row title-table">
                        <div className="title">Nombre</div>
                        <div className="title ">Media</div>
                        <div className="title">Aceptar</div>
                        <div className="title">Eliminar</div>
                    </div>
                    <div className="tab-row">


                        {userData && Array.isArray(userData) && userData.map((user, index) => (
                            <Fragment key={index}>
                                <div className="item">{user.username}</div>
                                <div className="item">{user.average.toFixed(2)}</div>
                                <div className="exp"><button className="prev" onClick={() => confirmDemand(user.id)} ><img src={acept} alt="home" /></button></div>
                                <div className="exp"><button onClick={() => deleteDemand(user.id)}  >X</button></div>
                            </Fragment>
                        ))}

                        

                      
                    </div>
                </div>
               
            </div>
            <TwoButtons />
        </div>

    )
}
