import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Home() {
    

    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
        confirmPassword: ''
    });

    const [errors, setErrors] = useState({});

    const navigate = useNavigate();

    const handleRegisterClick = () => {
        navigate('/login');  // Función para navegar a /register
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Clear previous errors
        setErrors({});

        const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*.,?¡¿!?]).{8,}$/;

        if (!passwordRegex.test(formData.password)) {
            setErrors(errors => ({ ...errors, password: "La contraseña debe tener al menos 8 caracteres, una mayúscula y un símbolo" }));
            return;
        }

        // Check if passwords match
        if (formData.password !== formData.confirmPassword) {
            setErrors(errors => ({ ...errors, password: "Las contraseñas no coinciden" }));
            return;
        }

        // Check username length
        if (formData.username.length < 2 || formData.username.length > 15) {
            setErrors(errors => ({ ...errors, username: "El usuario debe tener entre 2 y 15 caracteres" }));
            return;
        }

        // Check email format
        if (!/\S+@\S+\.\S+/.test(formData.email)) {
            setErrors(errors => ({ ...errors, email: "Formato de correo electrónico inválido" }));
            return;
        }

        try {
            let xhr = new XMLHttpRequest();
            var data = new FormData();
            data.append('username', formData.username);
            data.append('email', formData.email);
            data.append('password', formData.password);

            let url = `${process.env.REACT_APP_API_URL}/users`;
            //let url = 'http://wineapi/api/users';
            xhr.open("POST", url, true);
            xhr.onreadystatechange = function () {
                if (this.readyState === 4 && this.status === 200) {
                    console.log(this.responseText);
                    const user = JSON.parse(this.responseText);
                    localStorage.setItem('userLogged', JSON.stringify({ username: user.username, profile: user.profile, wines: user.wines, average: user.average, id: user.id }));
                    navigate('/edit-user');
                }
            }
            xhr.send(data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    return (
        <div className="winegangs-container">
            <div className="register-container">
                <h2>Nuevo usuario</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-line">
                        <label htmlFor="username">Usuario</label>
                        <input type="text" id="username" value={formData.username} onChange={handleChange} placeholder="Juan" />
                        
                    </div>
                    {errors.username && <p className="error">{errors.username}</p>}
                    <div className="form-line">
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" value={formData.email} onChange={handleChange} placeholder="juan@example.com" />
                    </div>
                    {errors.email && <p className="error">{errors.email}</p>}
                    <div className="form-line">
                        <label htmlFor="password">Contraseña</label>
                        <input type="password" id="password" value={formData.password} onChange={handleChange} placeholder="********" />
                    </div>
                    <div className="form-line">
                        <label htmlFor="confirmPassword">Repetir Contraseña</label>
                        <input type="password" id="confirmPassword" value={formData.confirmPassword} onChange={handleChange} placeholder="********" />
                    </div>
                    {errors.password && <p className="error">{errors.password}</p>}
                    <p className="link" onClick={handleRegisterClick} style={{ cursor: "pointer", color: "blue" }}>Login</p>
                    <button type="submit">Enviar</button>
                </form>
            </div>
        </div>
    );
}
