import React, { useState, useEffect, Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Profile from '../components/profileRed';
import TwoButtons from '../components/twoButtons';
import acept from '../images/aceptar.png';

export default function Crear() {

    const [winegangData, setWinegangData] = useState([]);
    const [searchText, setSearchText] = useState(''); 
    const [userRequest, setRequestData] = useState({ invitations: [], demands: [], winegangUsers: [] });
    const navigate = useNavigate();
    const [profileInfo, setProfileInfo] = useState({
        id: 0
    });

    useEffect(() => {
        // Cargar la informaci�n del usuario desde localStorage
        const storedUserInfo = localStorage.getItem('userLogged');
        if (storedUserInfo) {
            const userInfo = JSON.parse(storedUserInfo);
            console.log(userInfo);
            setProfileInfo({
                id: userInfo.id || 0,
            });
        }
    }, []);

    const fetchWinegangData = () => {
        const xhr = new XMLHttpRequest();
        const url = `${process.env.REACT_APP_API_URL}/winegangs`;
        //const url = `http://wineapi/api/winegangs`;

        xhr.open("GET", url, true);
        xhr.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    const winegangs = JSON.parse(this.responseText);
                    setWinegangData(winegangs);
                    console.log("Datos de las winegangs:", winegangs);
                } else {
                    console.error("Error al obtener los datos de las winegangs:", this.status, this.responseText);
                }
            }
        };
        xhr.send();
    };

    const fetchRequestData = () => {
        const xhr = new XMLHttpRequest();
        const url = `${process.env.REACT_APP_API_URL}/findByUserId/${profileInfo.id}`;

        xhr.open("GET", url, true);
        xhr.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    const userRequest = JSON.parse(this.responseText);
                    setRequestData(userRequest);
                    console.log("Requests:", userRequest);
                } else {
                    console.error("Error al obtener los datos de los requests:", this.status, this.responseText);
                }
            }
        };
        xhr.send();
    };
    useEffect(() => {
        if (profileInfo && profileInfo.id) { // A�adido check para asegurar que profileInfo tiene un id
            fetchRequestData();
            fetchWinegangData();
        }
    }, [profileInfo]);


    const redirectToUserPage = (winegangId) => {
        navigate(`/gangs/${winegangId}`);
    };

    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    };


    const createDemand = (winegangId) => {
        try {
            let xhr = new XMLHttpRequest();
            let data = new FormData();
            data.append('user_id', profileInfo.id);
            data.append('winegang_id', winegangId);

            let url = `${process.env.REACT_APP_API_URL}/demand`;
            xhr.open("POST", url, true);
            xhr.onreadystatechange = function () {
                if (this.readyState === 4) {
                    if (this.status === 201) {
                        console.log("Success:", this.responseText);
                        fetchRequestData();
                    } else {
                        console.error("Server responded with status:", this.status);
                    }
                }
            };
            xhr.send(data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    // Filtrado de los datos basado en el texto de b�squeda
    const filteredWinegangs = winegangData.filter(winegang => {
        const winegangId = Number(winegang.id); // Asegur�ndose de que winegang.id sea un n�mero

        const isWinegangUser = userRequest.winegangUsers.some(winegangUser => {
            return Number(winegangUser.winegang_id) === winegangId; // Convertir y comparar como n�meros
        });

        const hasInvitation = userRequest.invitations.some(invitation => {
            return Number(invitation.winegang_id) === winegangId; // Convertir y comparar como n�meros
        });

        const hasDemand = userRequest.demands.some(demand => {
            return Number(demand.winegang_id) === winegangId; // Convertir y comparar como n�meros
        });

        return !isWinegangUser && !hasInvitation && !hasDemand && winegang.winegang_name.toLowerCase().includes(searchText.toLowerCase());
    });


    return (
        <div className="winegangs-container">
            <Profile />
            <div className="gang-container">
                <h3>Buscar WineGang</h3>

                <div className="search-table">
                    <div className="input-row">
                        <input
                            className="input"
                            placeholder="Introducir nombre"
                            type="text"
                            value={searchText}
                            onChange={handleSearchChange} // Actualizaci�n del estado al escribir
                        ></input>
                    <button>Buscar</button>
                    </div>
                    <div className="tab-row title-table">
                        <div className="title">Nombre</div>
                        <div className="title ">Media</div>
                        <div className="title">Solicitar</div>
                    </div>
                    <div className="tab-row">
                        {filteredWinegangs.map((winegang, index) => (
                            <Fragment key={index}>
                                <div className="item" onClick={() => redirectToUserPage(winegang.id)}>{winegang.winegang_name} ({winegang.winegang_users_count})</div>
                                <div className="item">{winegang.average.toFixed(2)}%</div>
                                <div className="exp"><button className="prev" onClick={() => createDemand(winegang.id)} ><img src={acept} alt="home" /></button></div>
                            </Fragment>
                        ))}



                    </div>
                </div>

            </div>
            <TwoButtons />
        </div>

    )
}
