import React, { useState, useEffect } from 'react';
import icon from '../images/icon-v.png';
import instructions from '../images/instruct-v.png';
import Rinstructions from '../images/r-instruct-v.png';

const SlideComponent = ({ actual, first, second }) => {
  const [selectedButtonGroup1, setSelectedButtonGroup1] = useState(null);
  const [selectedButtonGroup2, setSelectedButtonGroup2] = useState(null);
    const [disableNextButton, setDisableNextButton] = useState(true);
    const [title, setTitle] = useState(null);

    useEffect(() => {
        const savedButtonGroup1 = localStorage.getItem('selected0');
        const savedButtonGroup2 = localStorage.getItem('selected1');
        const savedTest = JSON.parse(localStorage.getItem('currentTest') || '[]');
        setTitle(savedTest.title);
        if (savedButtonGroup1) {
            setSelectedButtonGroup1(savedButtonGroup1);
        }

        if (savedButtonGroup2) {
            setSelectedButtonGroup2(savedButtonGroup2);
        }
    }, []);

    useEffect(() => {
        const nextButton = document.querySelector('.btn-next');

        if (selectedButtonGroup1 !== null && selectedButtonGroup2 !== null) {
            nextButton.disabled = false; // Habilitar el botón
        } else {
            nextButton.disabled = true; // Deshabilitar el botón
        }
    }, [selectedButtonGroup1, selectedButtonGroup2]);


    const handleButtonClickGroup1 = (buttonId) => {
        setSelectedButtonGroup1(buttonId);
        localStorage.setItem('selected0', buttonId);
    };

    const handleButtonClickGroup2 = (buttonId) => {
        setSelectedButtonGroup2(buttonId);
        localStorage.setItem('selected1', buttonId);
    };
    const handleQuitInstructions = () => {
        const instructionsDiv = document.getElementById('instructions-div');
        if (instructionsDiv) {
            instructionsDiv.style.display = 'none';
        }
    };
  return (
      <div className="slide-container">
          <div className="overlay" id="instructions-div">
              <img src={instructions} alt="Imagen" className="centered-image dekstop" onClick={handleQuitInstructions} />
              <img src={Rinstructions} alt="Imagen" className="centered-image mobile" onClick={handleQuitInstructions} />
          </div>
          <img className="icon-test" src={icon} alt="icon" />
          <div className="actual-wine">{title}</div>
      <h2 className="mb-3">¡¡¡   Fase visual   !!!</h2>

      
      
      <div className="section-container" role="group">
              <div className="section-title">Intensidad del color (1 pt)</div>
              {first.question.map((item, index) => (
                  <button
                      key={index}
                      type="button"
                      className={`btn ${selectedButtonGroup1 === item.option ? 'btn-selected' : 'btn-olfatory'}`}
                      onClick={() => handleButtonClickGroup1(item.option)}
                  >
                      {item.option}
                  </button>
              ))}
      </div>

      <div className="section-container">
              <div className="section-title">Color del Vino  (2 pt)</div>
              {second.question.map((item, index) => (
                  <button
                      key={index}
                      type="button"
                      className={`btn ${selectedButtonGroup2 === item.option ? 'btn-selected' : 'btn-olfatory'}`}
                      onClick={() => handleButtonClickGroup2(item.option)}
                  >
                      {item.option}
                  </button>
              ))}
      </div>
    </div>
  );
};

export default SlideComponent;