import React from 'react';
import { Link } from 'react-router-dom';
import home from '../images/home-btn.png';
import back from '../images/back-btn.png';

const TwoButtons = () => {
    const handleGoBack = () => {
        window.history.back();
    };

    return (
        <div className="global-buttons-bh">
            <button className="main-button"><Link to="/"><img src={home} alt="home" /></Link></button>
            <button className="main-button back" onClick={handleGoBack}><img src={back} alt="back" /></button>
        </div>
    );
};

export default TwoButtons;