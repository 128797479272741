import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import userImage from '../images/user.jpg';

const Profile = () => {
    const [profileInfo, setProfileInfo] = useState({
        username: '',
        average: 0.00,
        wines: 0,
        profile: 0,
        id: 0
    });

    useEffect(() => {
        // Cargar la informaci�n del usuario desde localStorage
        const storedUserInfo = localStorage.getItem('userLogged');
        if (storedUserInfo) {
            const userInfo = JSON.parse(storedUserInfo);
            console.log(userInfo);
            setProfileInfo({
                username: userInfo.username,
                average: userInfo.average || 0.00,
                wines: userInfo.wines || 0,
                id: userInfo.id || 0,
                profile: userInfo.profile || 'default'
            });
        }
    }, []);

    const getProfileImageUrl = () => {
        if (profileInfo.profile) {
            try {
                return require(`../images/users/${profileInfo.profile}.jpeg`);
            } catch (err) {
                console.error('Failed to load user image:', err);
                return userImage; // Retorna imagen por defecto si falla
            }
        }
        return userImage;  // Imagen por defecto si profile es vac�o
    };



    return (
        <div className="user-info">
            <Link to={`/user/${profileInfo.id}`}>
            <div className="user-details">
                <img src={getProfileImageUrl()} alt="Imagen de usuario" className="user-image" />
                
            </div>
        </Link></div>
    );
};

export default Profile;