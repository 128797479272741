import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Profile from '../components/profile';
import TwoButtons from '../components/twoButtons';

export default function Crear() {

    const [formData, setFormData] = useState({
        name: '',
        text: ''
    });

    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({});

        // Check if passwords match
        if (formData.name.length < 2 || formData.name.length > 15) {
            setErrors(errors => ({ ...errors, name: "El nombre debe tener entre 2 y 15 caracteres" }));
            return;
        }

        // Check if text length is within bounds
        if (formData.text.length < 2 || formData.text.length > 249) {
            setErrors(errors => ({ ...errors, text: "La descripción debe tener entre 2 y 250 caracteres" }));
            return;
        }

        const userLogged = localStorage.getItem('userLogged');
        const userInfo = JSON.parse(userLogged);
        console.log('Hola');

        try {
            let xhr = new XMLHttpRequest();
            var data = new FormData();
            data.append('winegang_name', formData.name);
            data.append('text', formData.text);

            let url = `${process.env.REACT_APP_API_URL}/newgang/${userInfo.id}`;
            //let url = `http://wineapi/api/newgang/${userInfo.id}`;
            xhr.open("POST", url, true);
            xhr.onreadystatechange = function () {
                if (this.readyState === 4 && this.status === 200) {
                    console.log(this.responseText);
                    navigate('/gangs');
                }
            }
            xhr.send(data);
        } catch (error) {
            console.error('Error:', error);
        }
    };
    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    return (
        <div className="winegangs-container">
            <Profile />
            <div className="new-gang-container">
                <form onSubmit={handleSubmit}>
                    <input className="name" id="name" type="text" value={formData.name} onChange={handleChange} placeholder="Nombre de tu Gang"></input>
                    <textarea className="description" id="text" value={formData.text} onChange={handleChange} placeholder="ESCRIBE ALGO (OPCIPONAL)"></textarea>
                    {errors.name && <p className="error">{errors.name}</p>}
                    {errors.text && <p className="error">{errors.text}</p>}
                    <button className="submit">Crear</button>
                </form>
                
            </div>
            <TwoButtons />
        </div>

    )
}
