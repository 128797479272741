import React, { useState, useEffect, Fragment } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Profile from '../components/profileRed';
import TwoButtons from '../components/twoButtons';
import acept from '../images/aceptar.png';

export default function Crear() {

    const [userData, setUserData] = useState([]);
    const { id } = useParams();
    const [winegangData, setWinegangData] = useState(0);
    const [profileInfo, setProfileInfo] = useState({ id: 0 });
    const [searchText, setSearchText] = useState(''); // Estado para manejar el texto de b�squeda
    const [userRequest, setRequestData] = useState({ invitations: [], demands: [], winegangUsers: [] });
    const navigate = useNavigate();

    const fetchUserData = () => {
        const xhr = new XMLHttpRequest();
        const url = `${process.env.REACT_APP_API_URL}/users`;
        //const url = `http://wineapi/api/users`;

        xhr.open("GET", url, true);
        xhr.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    const users = JSON.parse(this.responseText);
                    setUserData(users);
                    console.log("Datos de los usuarios:", users);
                } else {
                    console.error("Error al obtener los datos de los usuarios:", this.status, this.responseText);
                }
            }
        };
        xhr.send();
    };

    const fetchWinegangData = () => {
        if (profileInfo && profileInfo.id) {  // Aseg�rate de que profileInfo est� correctamente establecido
            const xhr = new XMLHttpRequest();
            const url = `${process.env.REACT_APP_API_URL}/winegang/${id}`;
            xhr.open("GET", url, true);
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4 && xhr.status === 200) {
                    const data = JSON.parse(xhr.responseText);
                    setWinegangData(data);
                    if (Number(profileInfo.id) !== Number(data.admin_user_id)) {
                        navigate('/');

                    }
                }
            };
            xhr.send();
        }
    };
    const fetchRequestData = () => {
        const xhr = new XMLHttpRequest();
        const url = `${process.env.REACT_APP_API_URL}/findByWinegangId/${id}`;

        xhr.open("GET", url, true);
        xhr.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    const userRequest = JSON.parse(this.responseText);
                    setRequestData(userRequest);
                    console.log("Requests:", userRequest);
                } else {
                    console.error("Error al obtener los datos de los requests:", this.status, this.responseText);
                }
            }
        };
        xhr.send();
    };

    useEffect(() => {
        // Cargar la informaci�n del usuario desde localStorage
        const storedUserInfo = localStorage.getItem('userLogged');
        if (storedUserInfo) {
            const userInfo = JSON.parse(storedUserInfo);
            console.log(userInfo);
            setProfileInfo({
                id: userInfo.id || 0,
            });
        }
    }, []);


    useEffect(() => {
        if (profileInfo && profileInfo.id) { // A�adido check para asegurar que profileInfo tiene un id
            fetchUserData();
            fetchWinegangData();
            fetchRequestData();
        }
    }, [profileInfo]);


    const redirectToUserPage = (userId) => {
        navigate(`/user/${userId}`);
    };

    const createInvitation = (userId) => {
        try {
            let xhr = new XMLHttpRequest();
            let data = new FormData();
            data.append('user_id', userId);
            data.append('winegang_id', id);

            let url = `${process.env.REACT_APP_API_URL}/invitation`;
            xhr.open("POST", url, true);
            xhr.onreadystatechange = function () {
                if (this.readyState === 4) {
                    if (this.status === 201) {
                        console.log("Success:", this.responseText);
                        fetchRequestData();
                    } else {
                        console.error("Server responded with status:", this.status);
                    }
                }
            };
            xhr.send(data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    };
    const filteredUsers = userData.filter(userData => {
        // Convertir userData.id a n�mero para asegurar comparaci�n num�rica consistente
        const userId = Number(userData.id);

        const isAssociatedWinegangUser = userRequest.winegangUsers.some(winegangUser =>
            Number(winegangUser.user_id) === userId // Convertir y comparar como n�meros
        );

        const isInInvitation = userRequest.invitations.some(invitation =>
            Number(invitation.user_id) === userId // Convertir y comparar como n�meros
        );

        const isInDemand = userRequest.demands.some(demand =>
            Number(demand.user_id) === userId // Convertir y comparar como n�meros
        );

        return !isAssociatedWinegangUser && !isInInvitation && !isInDemand &&
            userData.username.toLowerCase().includes(searchText.toLowerCase());
    });


    return (
        <div className="winegangs-container">
            <Profile />
            <div className="gang-container">
                <h3>{winegangData.winegang ? winegangData.winegang.winegang_name : 'Cargando...'}</h3>
                <div className="description">{winegangData.winegang ? winegangData.winegang.text : 'Cargando...'}</div>
                <h2>Buscar Jugador</h2>
                <div className="search-table invitar">
                    <div className="input-row">
                        <input
                            className="input"
                            placeholder="Introducir nombre"
                            type="text"
                            value={searchText}
                            onChange={handleSearchChange} // Actualizaci�n del estado al escribir
                        ></input>
                        <button>Buscar</button>
                    </div>
                    <div className="tab-row title-table">
                        <div className="title">Nombre</div>
                        <div className="title ">Media</div>
                        <div className="title">Invitar</div>
                    </div>
                    <div className="tab-row">
                        
                        {filteredUsers.map((user, index) => (
                            <Fragment key={index}>
                                <div className="item" onClick={() => redirectToUserPage(user.id)}>{user.username} ({user.relevant_winegang.name})</div>
                                <div className="item">{user.average.toFixed(2)}%</div>
                                <div className="exp"><button className="prev" onClick={() => createInvitation(user.id)} ><img src={acept} alt="home" /></button></div>
                            </Fragment>
                        ))}


                    </div>
                </div>

            </div>
            <TwoButtons />
        </div>

    )
}
