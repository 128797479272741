import React, { useState, useEffect, Fragment } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Profile from '../components/profileRed';
import TwoButtons from '../components/twoButtons';
import acept from '../images/aceptar.png';

export default function Crear() {

    const [winegangData, setWinegangData] = useState(0);
    const [profileInfo, setProfileInfo] = useState({ id: 0 });


    useEffect(() => {
        // Cargar la informaci�n del usuario desde localStorage
        const storedUserInfo = localStorage.getItem('userLogged');
        if (storedUserInfo) {
            const userInfo = JSON.parse(storedUserInfo);
            console.log(userInfo);
            setProfileInfo(userInfo);
        }
    }, []);
    const fetchInvitationsData = () => {
        if (profileInfo && profileInfo.id) {  // Aseg�rate de que profileInfo est� correctamente establecido
            const xhr = new XMLHttpRequest();
            const url = `${process.env.REACT_APP_API_URL}/findInvitation/${profileInfo.id}`;
            xhr.open("GET", url, true);
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4 && xhr.status === 200) {
                    const data = JSON.parse(xhr.responseText);
                    setWinegangData(data);
                    console.log(data);
                }
            };
            xhr.send();
        }
    };

    useEffect(() => {
        if (profileInfo && profileInfo.id) { // A�adido check para asegurar que profileInfo tiene un id
            fetchInvitationsData();
        }
    }, [profileInfo]);

    const confirmInvitation = (winegang_id) => {
        if (profileInfo && profileInfo.id && winegang_id) { // Aseg�rate de que tanto profileInfo como winegang_id est�n definidos
            const xhr = new XMLHttpRequest();
            // Incluir ambos par�metros en la URL
            const url = `${process.env.REACT_APP_API_URL}/confirmInvitation/${profileInfo.id}/${winegang_id}`;
            xhr.open("GET", url, true);
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        const data = JSON.parse(xhr.responseText);
                        console.log(data);
                        fetchInvitationsData();
                    } else {
                        console.error("Failed to fetch data:", xhr.status, xhr.responseText);
                    }
                }
            };
            xhr.send();
        } else {
            console.error("Invalid or missing user ID or winegang ID");
        }
    };
    const deleteInvitation = (winegang_id) => {
        if (profileInfo && profileInfo.id && winegang_id) { // Aseg�rate de que tanto profileInfo como winegang_id est�n definidos
            const xhr = new XMLHttpRequest();
            // Incluir ambos par�metros en la URL
            const url = `${process.env.REACT_APP_API_URL}/deleteInvitation/${profileInfo.id}/${winegang_id}`;
            xhr.open("GET", url, true);
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        const data = JSON.parse(xhr.responseText);
                        console.log(data);
                        fetchInvitationsData();
                    } else {
                        console.error("Failed to fetch data:", xhr.status, xhr.responseText);
                    }
                }
            };
            xhr.send();
        } else {
            console.error("Invalid or missing user ID or winegang ID");
        }
    };
    return (
        <div className="winegangs-container">
            <Profile />
            <div className="gang-container">
                <h3>{profileInfo ? profileInfo.username : 'Cargando...'}</h3>

                <h2>Invitaciones</h2>

                <div className="sol-table">
                    <div className="tab-row title-table">
                        <div className="title">Winegang</div>
                        <div className="title ">Media</div>
                        <div className="title">Aceptar</div>
                        <div className="title">Eliminar</div>
                    </div>
                    <div className="tab-row">

                        {winegangData.winegangs && Array.isArray(winegangData.winegangs) && winegangData.winegangs.map((winegang, index) => (
                            <Fragment key={index}>
                                <div className="item">{winegang.winegang_name}</div>
                                <div className="item">{winegang.average.toFixed(2)}</div>
                                <div className="exp"><button className="prev" onClick={() => confirmInvitation(winegang.id)} ><img src={acept} alt="home" /></button></div>
                                <div className="exp"><button onClick={() => deleteInvitation(winegang.id)}  >X</button></div>
                            </Fragment>
                        ))}


                    </div>
                </div>

            </div>
            <TwoButtons />
        </div>

    )
}
