import React from 'react'
import { Link } from 'react-router-dom';
import Profile from '../components/profileRed';
import TwoButtons from '../components/twoButtons';
import arrow from '../images/arrow-white.png';

export default function Crear() {
    return (
        <div className="winegangs-container">
            <Profile />
            <div className="gang-container">
                <h3>Fumets</h3>
                <div className="description">Somos catadores... catamos vinitos... mas como en la luna no hay vino cantamos canciones!!!!!!</div>
                <div className="first-buttons">
                    <button><Link to="/gangs/invitar">Invitar</Link></button>
                    <button><Link to="/gangs/solicitudes">Solicitudes</Link></button>
                </div>
                <div className="main-table">
                    <div className="tab-row title-table">
                        <div className="title">Pos.</div>
                        <div className="title ">Nombre (Gang)</div>
                        <div className="title">Mes</div>
                        <div className="title">Media</div>
                        <div className="title">Vinos</div>
                        <div className="title">Expulsar</div>
                    </div>
                    <div className="tab-row">

                        <div className="pos">1</div>
                        <button className="item">Diego (Fumets)</button>
                        <div className="item">24</div>
                        <div className="item">3,5</div>
                        <div className="item">4</div>
                        <div className="exp"><button>X</button></div>

                        <div className="pos">2</div>
                        <button className="item">Javierdelah (Fumets)</button>
                        <div className="item">22</div>
                        <div className="item">3,6</div>
                        <div className="item">8</div>
                        <div className="exp"><button>X</button></div>

                        <div className="pos">3</div>
                        <button className="item">Lucas (Aguiluchos)</button>
                        <div className="item">56</div>
                        <div className="item">4,2</div>
                        <div className="item">12</div>
                        <div className="exp"><button>X</button></div>

                        <div className="pos">4</div>
                        <button className="item">Carolina (Llamas)</button>
                        <div className="item">89</div>
                        <div className="item">2,8</div>
                        <div className="item">16</div>
                        <div className="exp"><button>X</button></div>

                        <div className="pos">5</div>
                        <button className="item">Pedro (Dragones)</button>
                        <div className="item">37</div>
                        <div className="item">4,0</div>
                        <div className="item">20</div>
                        <div className="exp"><button>X</button></div>

                        <div className="pos">6</div>
                        <button className="item">Ana (Tigres)</button>
                        <div className="item">73</div>
                        <div className="item">3,9</div>
                        <div className="item">24</div>
                        <div className="exp"><button>X</button></div>

                        <div className="pos">7</div>
                        <button className="item">Marcos (Panteras)</button>
                        <div className="item">45</div>
                        <div className="item">3,1</div>
                        <div className="item">28</div>
                        <div className="exp"><button>X</button></div>

                        <div className="pos">8</div>
                        <button className="item">Elena (�guilas)</button>
                        <div className="item">61</div>
                        <div className="item">4,5</div>
                        <div className="item">32</div>
                        <div className="exp"><button>X</button></div>

                        <div className="pos">9</div>
                        <button className="item">Sof�a (Tigres)</button>
                        <div className="item">84</div>
                        <div className="item">4,3</div>
                        <div className="item">36</div>
                        <div className="exp"><button>X</button></div>

                        <div className="pos">10</div>
                        <button className="item">Gabriel (Llamas)</button>
                        <div className="item">42</div>
                        <div className="item">2,9</div>
                        <div className="item">40</div>
                        <div className="exp"><button>X</button></div>
                    </div>
                </div>
                <div className="second-buttons">
                    <button>Mensual</button>
                    <button>General</button>
                </div>
            </div>
            <TwoButtons />
        </div>

    )
}
